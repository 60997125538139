import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ForumComment, ForumPost, NotificationPreferenceType, PostState } from '../types';
import forumsService from '../services/forumsService';
import groupForumsConstants from '../constants/groupForumsConstants';
import useCursoredData from '../hooks/useCursoredData';
import useForumCategories from '../hooks/useForumCategories';
import { CompareComments } from '../utils/typeComparison';

export const PostContext = createContext<PostState | undefined>(undefined);

export const usePost = (): PostState => {
  const resource = useContext(PostContext);
  if (!resource) {
    throw new Error('usePost must be used within a PostProvider');
  }
  return resource;
};

export type PostProviderProps = {
  groupId: number;
  categoryId: string;
  postId: string;
  initialCommentId?: string;
  children: React.ReactNode;
};

export function PostProvider({
  children,
  groupId,
  categoryId,
  initialCommentId,
  postId
}: PostProviderProps): JSX.Element {
  const [isLoadingPost, setIsLoadingPost] = useState<boolean>(true);
  const [loadingPostError, setLoadingPostError] = useState<boolean>(false);
  const [scrollToCommentId, setScrollToCommentId] = useState<string | undefined>(initialCommentId);
  const [post, setPost] = useState<ForumPost | null>(null);

  const { forumCategories } = useForumCategories(groupId);

  const categoryName = useMemo(() => {
    const category = forumCategories.find(c => c.id === categoryId);
    return category?.name || '';
  }, [categoryId, forumCategories]);

  const markPostAsRead = async (
    postGroupId: number,
    postCategoryId: string,
    Id: string,
    lastSeenCommentId: string
  ) => {
    try {
      await forumsService.markGroupForumPostAsRead(
        postGroupId,
        postCategoryId,
        Id,
        lastSeenCommentId
      );
    } catch {
      // Intentionally ignoring errors here because marking the post as read is non-critical
      // and should not block the user experience if it fails.
    }
  };

  const fetchPost = useCallback(async () => {
    try {
      setIsLoadingPost(true);
      setLoadingPostError(false);
      const response = await forumsService.getGroupForumPostsByIds(groupId, categoryId, [postId]);
      setPost(response.data[0]);
    } catch {
      setLoadingPostError(true);
    } finally {
      setIsLoadingPost(false);
    }
  }, [categoryId, groupId, postId]);

  const fetchComments = useCallback(
    async (cursor: string | null) => {
      const response = await forumsService.getGroupForumComments(
        groupId,
        categoryId,
        postId,
        groupForumsConstants.pageCounts.commentsPerPage,
        cursor,
        // use first comment ID to fetch comments in ascending chrono order
        // we can remove this if we change default API behavior
        initialCommentId ?? post?.firstComment.id
      );
      return response;
    },
    [categoryId, groupId, initialCommentId, post, postId]
  );

  const onAddComments = useCallback(
    async (newItems: ForumComment[]) => {
      // Mark latest comment as last seen
      await markPostAsRead(groupId, categoryId, postId, newItems[newItems.length - 1].id);
    },
    [categoryId, groupId, postId]
  );

  const {
    items: comments,
    isLoadingInitialItems: isLoadingComments,
    isFetchingNextPage: isFetchingNextCommentsPage,
    isFetchingPreviousPage: isFetchingPreviousCommentsPage,
    error: errorLoadingComments,
    refetch: refetchComments,
    fetchMore: fetchNextCommentsPage,
    fetchPrevious: fetchPreviousCommentsPage,
    addItems: addComments,
    updateItem: updateComment,
    setItems: setComments,
    hasMore: hasNextComments
  } = useCursoredData<ForumComment>({
    fetchItems: fetchComments,
    initialCursor: null,
    compareFn: CompareComments,
    onAddItems: onAddComments
  });

  const hasPreviousComments = useMemo(() => {
    if (!post) return false;
    const firstCommentId = post.firstComment.id;
    return !comments.some(comment => comment.id === firstCommentId);
  }, [post, comments]);

  const addReplies = useCallback(
    (commentId: string, newComments: ForumComment[], insertAtIndex?: number) => {
      const comment = comments.find(c => c.id === commentId);
      if (!comment) {
        return;
      }
      const updatedComment = { ...comment };
      if (!updatedComment.replies) {
        updatedComment.replies = [];
      }
      if (!updatedComment.threadId) {
        updatedComment.threadId = newComments[0].parentId;
      }
      const repliesStartIndex = insertAtIndex ?? updatedComment.replies.length;
      updatedComment.replies = [
        ...updatedComment.replies.slice(0, repliesStartIndex),
        ...newComments,
        ...updatedComment.replies.slice(repliesStartIndex)
      ];
      // Get rid of duplicates
      // This can happen when a user posts a reply on a thread that hasn't loaded yet
      // The loading will add the same reply to the thread
      updatedComment.replies = updatedComment.replies.filter(
        (currentComment, index, self) => self.findIndex(c => c.id === currentComment.id) === index
      );

      updateComment(updatedComment);
    },
    [comments, updateComment]
  );

  const getComment = useCallback(
    (commentId: string, parentCommentId?: string): ForumComment | null => {
      let searchComments = comments;
      if (parentCommentId) {
        const parentComment = comments.find(c => c.id === parentCommentId);
        if (parentComment) {
          searchComments = parentComment.replies;
        } else {
          return null;
        }
      }
      const comment = searchComments.find(c => c.id === commentId);
      if (comment) {
        return comment;
      }
      return null;
    },
    [comments]
  );

  const removeComment = useCallback(
    (commentId: string) => {
      const comment = comments.find(c => c.id === commentId);
      if (!comment) {
        return;
      }
      const updatedComments = comments.filter(c => c.id !== commentId);
      setComments(updatedComments);
    },
    [comments, setComments]
  );

  const removeReply = useCallback(
    (parentCommentId: string, commentId: string) => {
      const parentComment = comments.find(c => c.id === parentCommentId);
      if (!parentComment) {
        return;
      }
      const reply = parentComment.replies.find(c => c.id === commentId);
      if (!reply) {
        return;
      }
      const updatedReplies = parentComment.replies.filter(c => c.id !== commentId);
      const updatedParentComment = { ...parentComment, replies: updatedReplies };
      updateComment(updatedParentComment);
    },
    [comments, updateComment]
  );

  const editComment = useCallback(
    (updatedComment: ForumComment) => {
      updateComment(updatedComment);
    },
    [updateComment]
  );

  const editReply = useCallback(
    (updatedComment: ForumComment, parentCommentId: string) => {
      const parentComment = comments.find(c => c.id === parentCommentId);
      if (!parentComment) {
        return;
      }
      const updatedReplies = parentComment.replies.map(c =>
        c.id === updatedComment.id ? updatedComment : c
      );
      const updatedParentComment = { ...parentComment, replies: updatedReplies };
      updateComment(updatedParentComment);
    },
    [comments, updateComment]
  );

  const handleCreateComment = useCallback(
    async ({
      content,
      parentCommentId,
      mentioningReplyId
    }: {
      content: string;
      parentCommentId?: string;
      mentioningReplyId?: string;
    }): Promise<void> => {
      let repliesToCommentId = parentCommentId;
      if (parentCommentId === post?.firstComment.id) {
        repliesToCommentId = undefined;
      }
      const response = await forumsService.createGroupForumComment(
        groupId,
        categoryId,
        postId,
        content,
        repliesToCommentId
      );
      if (repliesToCommentId) {
        // insert the reply directly after the reply it is replying to until refresh
        const parentComment = getComment(repliesToCommentId);
        const replyCommentIndex = parentComment?.replies?.findIndex(
          c => c.id === mentioningReplyId
        );
        const insertAtIndex = replyCommentIndex !== undefined ? replyCommentIndex + 1 : 0;
        addReplies(repliesToCommentId, [response], insertAtIndex);
      } else {
        addComments({ newItems: [response], addToFront: false });
      }
      setScrollToCommentId(response.id);
    },
    [post, groupId, categoryId, postId, getComment, addReplies, addComments]
  );

  const handleEditComment = useCallback(
    async ({
      content,
      commentId,
      parentCommentId
    }: {
      content: string;
      commentId: string;
      parentCommentId?: string;
    }): Promise<void> => {
      const threadId = parentCommentId ? getComment(parentCommentId)?.threadId : undefined;
      const channelId = threadId ?? postId;
      const response = await forumsService.updateGroupForumComment(
        groupId,
        categoryId,
        channelId,
        commentId,
        content
      );
      if (parentCommentId) {
        editReply(response, parentCommentId);
      } else {
        // edit response does not include replies, so keep any loaded replies from original comment
        if (!response.replies?.length) {
          response.replies = getComment(commentId)?.replies ?? [];
        }
        editComment(response);
      }
    },
    [categoryId, groupId, postId, getComment, editComment, editReply]
  );

  const handleDeleteComment = useCallback(
    async (commentId: string, parentCommentId?: string): Promise<boolean> => {
      try {
        let channelId = postId;
        // If we are deleting a reply we send in the thread id as the channel id
        if (parentCommentId) {
          const parentComment = comments.find(c => c.id === parentCommentId);
          if (!parentComment) {
            return false;
          }
          if (!parentComment.threadId) {
            return false;
          }
          channelId = parentComment.threadId;
        }
        await forumsService.deleteGroupForumComment(groupId, categoryId, channelId, commentId);
        if (parentCommentId) {
          removeReply(parentCommentId, commentId);
        } else {
          removeComment(commentId);
        }
      } catch (error) {
        return false;
      }
      return true;
    },
    [postId, groupId, categoryId, comments, removeReply, removeComment]
  );

  const fetchPostNotificationPreference = useCallback(async () => {
    if (!post) {
      return;
    }
    const result = await forumsService.getPostNotificationPreference(groupId, categoryId, postId);
    const updatedPost = { ...post, notificationPreference: result.preference };
    setPost(updatedPost);
  }, [categoryId, groupId, postId, post]);

  const fetchCommentNotificationPreference = useCallback(
    async commentId => {
      const comment = comments.find(c => c.id === commentId);
      if (!comment) {
        return;
      }

      const result = await forumsService.getCommentNotificationPreference(
        groupId,
        categoryId,
        postId,
        commentId
      );

      const updatedComment = { ...comment, notificationPreference: result.preference };

      updateComment(updatedComment);
    },
    [categoryId, comments, groupId, postId, updateComment]
  );

  const togglePostNotifications = useCallback(async () => {
    if (!post) {
      return;
    }

    const { notificationPreference } = post;
    const newIsSubscribed = notificationPreference === NotificationPreferenceType.None;

    await forumsService.togglePostNotificationSubscription(
      groupId,
      categoryId,
      postId,
      newIsSubscribed
    );

    const updatedPost = {
      ...post,
      notificationPreference: newIsSubscribed
        ? NotificationPreferenceType.All
        : NotificationPreferenceType.None
    };
    setPost(updatedPost);
  }, [groupId, categoryId, postId, post]);

  const toggleCommentNotifications = useCallback(
    async (commentId: string) => {
      const comment = comments.find(c => c.id === commentId);
      if (!comment) {
        return;
      }

      const { notificationPreference } = comment;
      const newIsSubscribed = notificationPreference === NotificationPreferenceType.None;

      await forumsService.toggleCommentNotificationSubscription(
        groupId,
        categoryId,
        postId,
        commentId,
        newIsSubscribed
      );

      const updatedComment = {
        ...comment,
        notificationPreference: newIsSubscribed
          ? NotificationPreferenceType.All
          : NotificationPreferenceType.None
      };
      updateComment(updatedComment);
    },
    [groupId, categoryId, postId, comments, updateComment]
  );

  const clearScrollToCommentId = useCallback(() => {
    setScrollToCommentId(undefined);
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-void
    void fetchPost();
  }, [fetchPost]);

  useEffect(() => {
    // wait until post is loaded so we have the first comment id
    // can remove this check if we change API default to fetch comments in ascending chrono order
    if (!isLoadingPost) {
      refetchComments();
    }
  }, [isLoadingPost, refetchComments]);

  return (
    <PostContext.Provider
      value={{
        groupId,
        categoryId,
        categoryName,
        postId,
        initialCommentId,
        isLoadingPost,
        loadingPostError,
        fetchPost,
        post,
        handleCreateComment,
        handleEditComment,
        isLoadingComments,
        refetchComments,
        isFetchingNextCommentsPage,
        isFetchingPreviousCommentsPage,
        fetchNextCommentsPage,
        fetchPreviousCommentsPage,
        fetchPostNotificationPreference,
        fetchCommentNotificationPreference,
        togglePostNotifications,
        toggleCommentNotifications,
        errorLoadingComments,
        comments,
        getComment,
        addReplies,
        handleDeleteComment,
        scrollToCommentId,
        clearScrollToCommentId,
        hasNextComments,
        hasPreviousComments
      }}>
      {children}
    </PostContext.Provider>
  );
}
