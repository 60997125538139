import React, { useCallback, useState, useMemo } from 'react';
import { Loading } from 'react-style-guide';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import classNames from 'classnames';
import CreatePostButton from '../CreatePostButton';
import { groupsConfig } from '../../translation.config';
import EditableContentFieldInput from './EditableContentFieldInput';
import NativeFooter from '../NativeFooter';
import SectionHeader from '../SectionHeader';
import TextContentEditorError from './TextContentEditorError';
import {
  GetForumPostContentValidationErrorKey,
  GetForumPostTitleValidationErrorKey
} from '../../utils/groupForumsValidation';

export type TextContentEditorProps = {
  hasTitle: boolean;
  headerText: string;
  contentPlaceholder: string;
  submitText: string;
  customControls?: JSX.Element;
  titleMaxLength?: number;
  contentMaxLength: number;
  defaultTitle?: string;
  defaultContent?: string;
  titleLocked?: boolean;
  errorKey?: string | null;
  onSubmit: (params: { title: string; content: string }) => Promise<void>;
  onBack: () => void;
  onChange?: (params: { title: string; content: string }) => void;
  isLoading?: boolean;
} & WithTranslationsProps;

const TextContentEditor = ({
  hasTitle,
  headerText,
  contentPlaceholder,
  submitText,
  customControls,
  titleMaxLength,
  contentMaxLength,
  defaultTitle,
  defaultContent,
  titleLocked,
  errorKey,
  onSubmit,
  onBack,
  onChange,
  isLoading,
  translate
}: TextContentEditorProps): JSX.Element => {
  const [title, setTitle] = useState<string>(defaultTitle ?? '');
  const [content, setContent] = useState<string>(defaultContent ?? '');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onSubmitClicked = useCallback(async () => {
    setIsSubmitting(true);
    await onSubmit({ title, content });
    setIsSubmitting(false);
  }, [title, content, onSubmit]);

  const contentValidationError = useMemo(() => {
    const validationErrorKey = GetForumPostContentValidationErrorKey(content);
    if (validationErrorKey) {
      return translate(validationErrorKey);
    }
    return undefined;
  }, [content, translate]);

  const titleValidationError = useMemo(() => {
    const validationErrorKey = GetForumPostTitleValidationErrorKey(title);
    if (validationErrorKey) {
      return translate(validationErrorKey);
    }
    return undefined;
  }, [title, translate]);

  const onTitleChanged = useCallback(
    (value: string) => {
      setTitle(value);
      onChange?.({ title: value, content });
    },
    [onChange, content]
  );

  const onContentChanged = useCallback(
    (value: string) => {
      setContent(value);
      onChange?.({ title, content: value });
    },
    [onChange, title]
  );

  const isSubmitDisabled = useMemo(() => {
    return (
      isSubmitting ||
      isLoading ||
      !!errorKey ||
      !content ||
      !!contentValidationError ||
      (hasTitle && (!title || !!titleValidationError))
    );
  }, [
    isSubmitting,
    isLoading,
    errorKey,
    content,
    contentValidationError,
    hasTitle,
    title,
    titleValidationError
  ]);

  return (
    <div className='text-content-editor'>
      <SectionHeader headerText={headerText} onBack={onBack} />
      {!!customControls && (
        <div className='text-content-editor-controls'>
          {customControls}
          <hr className='text-content-editor-divider' />
        </div>
      )}
      <div className='text-content-editor-body'>
        {isLoading ? (
          <Loading />
        ) : (
          <React.Fragment>
            {hasTitle && (
              <React.Fragment>
                <h5 className='text-content-editor-label'>{translate('Label.PostTitle')}</h5>
                <EditableContentFieldInput
                  textAreaClassName={classNames(
                    'text-content-editor-title',
                    titleLocked && 'text-content-editor-title-locked'
                  )}
                  fieldName='title'
                  defaultValue={defaultTitle}
                  placeholder={translate('Label.Title')}
                  maxLength={titleMaxLength}
                  onChange={onTitleChanged}
                  locked={titleLocked}
                  autoResize
                  validationError={titleValidationError}
                />
              </React.Fragment>
            )}
            <h5 className='text-content-editor-label'>{translate('Label.PostContent')}</h5>
            <EditableContentFieldInput
              className='text-content-editor-content-container'
              textAreaClassName='text-content-editor-content'
              fieldName='content'
              defaultValue={defaultContent}
              placeholder={contentPlaceholder ?? translate('Label.WriteSomething')}
              maxLength={contentMaxLength}
              onChange={onContentChanged}
              autoResize
              validationError={contentValidationError}
            />
          </React.Fragment>
        )}
      </div>
      <NativeFooter>
        <div className='text-content-editor-footer-mobile'>
          {errorKey ? <TextContentEditorError message={translate(errorKey)} /> : null}
          <CreatePostButton
            label={submitText}
            onClick={onSubmitClicked}
            disabled={isSubmitDisabled}
          />
        </div>
      </NativeFooter>
      <div className='text-content-editor-footer-desktop'>
        <div className='text-content-editor-buttons-desktop'>
          <button
            className='text-content-editor-cancel btn-control-md'
            type='button'
            onClick={onBack}>
            {translate('Action.Cancel')}
          </button>
          <CreatePostButton
            label={submitText}
            onClick={onSubmitClicked}
            disabled={isSubmitDisabled}
          />
        </div>
        {errorKey ? <TextContentEditorError message={translate(errorKey)} /> : null}
      </div>
    </div>
  );
};

TextContentEditor.displayName = 'TextContentEditor';

export default withTranslations(TextContentEditor, groupsConfig);
