import React, { ReactChild, ReactElement, createContext, useReducer } from 'react';
import { UIAction } from './forumsUIAction';
import forumsUIReducer from '../reducers/forumsUIReducer';
import useViewportSize from '../../shared/hooks/useViewportSize';

export type UIState = {
  blockUserDialogContext?: {
    userId: number;
  };
};

export type ForumsUIContextType = {
  layout: { useInlineReply: boolean };
  state: UIState;
  dispatch: React.Dispatch<UIAction>;
};

const initialUiState: UIState = {
  blockUserDialogContext: undefined
};

export const ForumsUIContext = createContext<ForumsUIContextType>({
  layout: {
    useInlineReply: false
  },
  state: initialUiState,
  dispatch: () => true
});

type ForumsUIContextProviderProps = {
  children: ReactChild;
};

export const ForumsUIContextProvider = ({
  children
}: ForumsUIContextProviderProps): ReactElement => {
  const [state, dispatch] = useReducer(forumsUIReducer, initialUiState);
  const { isSmallViewport } = useViewportSize();

  const layout = {
    useInlineReply: !isSmallViewport
  };

  return (
    <ForumsUIContext.Provider value={{ layout, state, dispatch }}>
      {children}
    </ForumsUIContext.Provider>
  );
};
