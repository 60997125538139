import React, { ReactNode, useCallback, useState, useMemo, useRef } from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import classNames from 'classnames';
import { Button } from 'react-style-guide';
import { groupsConfig } from '../../translation.config';
import EditableContentFieldInput, { EditableContentFieldHandle } from './EditableContentFieldInput';
import AccessibleDivButton from '../../../shared/components/AccessibleDivButton';
import { GetForumCommentContentValidationErrorKey } from '../../utils/groupForumsValidation';

export type ContentComposerProps = {
  className?: string;
  autoFocus?: boolean;
  inputRef?: React.RefObject<EditableContentFieldHandle>;
  defaultContent?: string;
  errorMessage?: string;
  label?: ReactNode;
  disabled?: boolean;
  submitDisabled?: boolean;
  onChange?: (value: string) => void;
  onSubmit: (value: string) => Promise<void>;
  onCancel?: () => void;
  onClose?: () => void;
} & WithTranslationsProps;

const ContentComposer = ({
  className,
  autoFocus,
  defaultContent,
  errorMessage,
  label,
  disabled,
  submitDisabled,
  onChange,
  onSubmit,
  onCancel,
  onClose,
  inputRef,
  translate
}: ContentComposerProps): JSX.Element => {
  const [content, setContent] = useState<string>(defaultContent ?? '');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const backupRef = useRef<EditableContentFieldHandle>(null);
  const commentComposerRef = inputRef || backupRef;

  const contentValidationError = useMemo(() => {
    const errorKey = GetForumCommentContentValidationErrorKey(content);
    if (errorKey) {
      return translate(errorKey);
    }
    return undefined;
  }, [content, translate]);

  const onContentChanged = useCallback(
    (value: string) => {
      setContent(value);
      onChange?.(value);
    },
    [onChange]
  );

  const onCancelClicked = useCallback(() => {
    commentComposerRef.current?.clearText();
    onContentChanged?.('');
    onCancel?.();
  }, [commentComposerRef, onContentChanged, onCancel]);

  const onSubmitClicked = useCallback(async () => {
    setIsSubmitting(true);
    await onSubmit(content);
    setIsSubmitting(false);
  }, [onSubmit, content]);

  const hasValidContent = useMemo(() => {
    const trimmedContent = content.trim();
    if (defaultContent && trimmedContent === defaultContent.trim()) {
      return false;
    }
    return trimmedContent.length > 0;
  }, [content, defaultContent]);

  const displayedErrorMessage = useMemo(() => errorMessage || contentValidationError, [
    contentValidationError,
    errorMessage
  ]);

  return (
    <div className={classNames('content-composer', className)}>
      {label && (
        <div className='content-composer-header'>
          <div className='content-composer-label'>{label}</div>
          {onClose && (
            <AccessibleDivButton
              aria-label={translate('Action.Close')}
              onClick={onClose}
              className='content-composer-close-button'>
              <span className='content-composer-close-icon' />
            </AccessibleDivButton>
          )}
        </div>
      )}
      <div className='content-composer-body'>
        <EditableContentFieldInput
          ref={commentComposerRef}
          className='content-composer-textarea'
          textAreaClassName='content-composer-textarea-input'
          placeholder={translate('Label.WriteComment')}
          defaultValue={defaultContent}
          showCharacterCount={false}
          maxTextFieldHeight={100}
          locked={disabled}
          onChange={onContentChanged}
          autoFocus={autoFocus}
          autoResize
        />
        <div className='content-composer-footer'>
          <div className='content-composer-buttons'>
            {!!onCancel && (
              <Button
                variant={Button.variants.secondary}
                size={Button.sizes.small}
                width={Button.widths.default}
                onClick={onCancelClicked}
                isDisabled={disabled}>
                {translate('Action.Cancel')}
              </Button>
            )}
            <Button
              variant={Button.variants.growth}
              size={Button.sizes.small}
              width={Button.widths.default}
              onClick={onSubmitClicked}
              isDisabled={
                !hasValidContent || disabled || submitDisabled || !!contentValidationError
              }
              isLoading={isSubmitting}>
              {translate('Action.Submit')}
            </Button>
          </div>
          {!!displayedErrorMessage && (
            <div
              className={classNames({
                'content-composer-error': !!errorMessage,
                'content-composer-validation-error': !!contentValidationError
              })}>
              {!!errorMessage && (
                <div className='content-composer-error-icon'>
                  <span className='icon-status-alert' />
                </div>
              )}
              <div
                className={classNames({
                  'content-composer-error-message': !!errorMessage,
                  'content-composer-validation-error-message text-error': !!contentValidationError
                })}>
                {displayedErrorMessage}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default withTranslations(ContentComposer, groupsConfig);
