import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSystemFeedback } from 'react-style-guide';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { useHistory } from 'react-router-dom';
import { groupsConfig } from '../translation.config';
import { ForumCategory } from '../types';
import CategoriesList from './CategoriesList';
import forumsService from '../services/forumsService';
import groupForumsConstants from '../constants/groupForumsConstants';
import PostPreviewList from './PostPreviewList';
import CreatePostButton from '../components/CreatePostButton';
import NativeFooter from '../components/NativeFooter';
import { useForumPermissions } from '../contexts/ForumPermissionsContext';
import { logGroupForumsClickEvent } from '../utils/logging';
import ForumSectionDisclaimer from '../components/ForumSectionDisclaimer';

export type CategoriesProps = {
  groupId: number;
  paramCategoryId: string | null;
} & WithTranslationsProps;

const Categories = memo(
  ({ groupId, paramCategoryId, translate }: CategoriesProps): JSX.Element => {
    const history = useHistory();
    const { canCreatePost } = useForumPermissions();
    const [isLoadingCategories, setIsLoadingCategories] = useState<boolean>(true);
    const [loadingCategoriesError, setLoadingCategoriesError] = useState<boolean>(false);
    const [forumCategories, setForumCategories] = useState<ForumCategory[]>([]);
    const [activeCategoryId, setActiveCategoryId] = useState('');
    const { SystemFeedbackComponent, systemFeedbackService } = useSystemFeedback();

    const setActiveCategory = useCallback(
      (categoryId: string): void => {
        setActiveCategoryId(categoryId);
        history.push(groupForumsConstants.router.getCategoryRoute(categoryId));
        logGroupForumsClickEvent({
          groupId,
          clickTargetType: 'changeCategory',
          clickTargetId: categoryId
        });
      },
      [groupId, setActiveCategoryId, history]
    );

    const fetchForumCategories = useCallback(async () => {
      try {
        setIsLoadingCategories(true);
        setLoadingCategoriesError(false);
        const response = await forumsService.getGroupForumCategories(groupId, false);
        setForumCategories(response.data);
      } catch {
        systemFeedbackService.warning(translate('NetworkError'));
        setLoadingCategoriesError(true);
      } finally {
        setIsLoadingCategories(false);
      }
    }, [groupId, systemFeedbackService, translate]);

    const onCreatePost = useCallback(() => {
      history.push(groupForumsConstants.router.getPostCreateRoute(activeCategoryId));
      logGroupForumsClickEvent({
        groupId,
        clickTargetType: 'goToPostComposerCreate',
        clickTargetId: activeCategoryId
      });
    }, [groupId, activeCategoryId, history]);

    const activeCategoryName = useMemo(() => {
      const activeCategory = forumCategories.find(category => category.id === activeCategoryId);
      return activeCategory?.name || '';
    }, [activeCategoryId, forumCategories]);

    useEffect(() => {
      // eslint-disable-next-line no-void
      void fetchForumCategories();
    }, [fetchForumCategories]);

    useEffect(() => {
      if (!forumCategories.length) return;
      const newCategoryId = paramCategoryId || forumCategories[0].id;
      // We have an invalid category id in the url, let's redirect to the first category
      if (!forumCategories.find(category => category.id === newCategoryId)) {
        setActiveCategory(forumCategories[0].id);
        return;
      }
      if (newCategoryId === activeCategoryId) return;
      setActiveCategoryId(newCategoryId);
    }, [activeCategoryId, paramCategoryId, forumCategories, setActiveCategory]);

    if (loadingCategoriesError) {
      return (
        <ForumSectionDisclaimer
          iconClassName='icon-status-alert'
          heading={translate('Error.LoadCategoryTitle')}
          message={translate('Error.ReloadingSubtitle')}
          buttonText={translate('Action.RetryLoadingForums')}
          onClick={fetchForumCategories}
        />
      );
    }

    return (
      <div className='group-forums-categories'>
        <div className='group-forums-categories-list-container'>
          <CategoriesList
            categories={forumCategories}
            activeCategoryId={activeCategoryId}
            onSetActiveCategory={setActiveCategory}
            isLoading={isLoadingCategories}
          />
        </div>
        <div className='group-forums-categories-header'>
          <h2 className='group-forums-categories-header-category-name'>{activeCategoryName}</h2>
          <CreatePostButton
            disabled={!canCreatePost}
            label={translate('Action.CreatePost')}
            onClick={onCreatePost}
          />
        </div>
        <PostPreviewList groupId={groupId} categoryId={activeCategoryId} />
        <NativeFooter fixed>
          <CreatePostButton
            disabled={!canCreatePost}
            label={translate('Action.CreatePost')}
            onClick={onCreatePost}
          />
        </NativeFooter>
        <SystemFeedbackComponent />
      </div>
    );
  }
);

Categories.displayName = 'Categories';

export default withTranslations(Categories, groupsConfig);
