import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { useSystemFeedback } from 'react-style-guide';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { ForumComment, Reaction, CommentCreatorInfo } from '../types';
import { groupsConfig } from '../translation.config';
import UserDisplay from './UserDisplay';
import CommentReactions from './CommentReactions';
import groupForumsConstants, { CommentVariants } from '../constants/groupForumsConstants';
import forumsService from '../services/forumsService';
import { usePost } from '../contexts/PostContext';
import { useComposer } from '../contexts/ComposerContext';
import CommentReplies from '../containers/CommentReplies';
import CommentComposer from './CommentComposer';
import AccessibleDivButton from '../../shared/components/AccessibleDivButton';
import useCursoredData from '../hooks/useCursoredData';
import useForumsUIContext from '../hooks/useForumsUIContext';
import { useForumPermissions } from '../contexts/ForumPermissionsContext';
import PostMenu from './PostMenu';
import CommentMenu from './CommentMenu';
import { CompareComments } from '../utils/typeComparison';
import { logGroupForumsClickEvent } from '../utils/logging';
import useReplyDisabledState from '../hooks/useReplyDisabledState';
import ConditionalTooltip from '../../shared/components/ConditionalTooltip';
import ScrollFlashOverlay from './ScrollFlashOverlay';

export type CommentProps = {
  id: string;
  createdBy: number;
  creatorInfo: CommentCreatorInfo;
  createdAt: string;
  updatedAt: string;
  content: string;
  title?: string;
  threadId: string | null; // The id of the channel with the thread of comments replying to this comment (if there is one)
  channelId: string; // The id of the channel this comment is in
  variant: CommentVariants;
  isActive: boolean;
  replies: ForumComment[];
  reactions: Reaction[];
  parentCommentId?: string;
} & WithTranslationsProps;

const Comment = ({
  id,
  threadId,
  channelId,
  title,
  variant,
  isActive,
  replies,
  createdBy,
  createdAt,
  updatedAt,
  content,
  parentCommentId,
  reactions,
  creatorInfo,
  translate
}: CommentProps): JSX.Element => {
  const history = useHistory();
  const { systemFeedbackService } = useSystemFeedback();
  const {
    groupId,
    categoryId,
    postId,
    post,
    scrollToCommentId,
    clearScrollToCommentId,
    addReplies,
    togglePostNotifications,
    toggleCommentNotifications,
    fetchPostNotificationPreference,
    fetchCommentNotificationPreference
  } = usePost();
  const { setReplyToCommentOrPost, setReplyToCommentReply } = useComposer();
  const { canReact } = useForumPermissions();
  const [showReplies, setShowReplies] = useState<boolean>(false);

  const onDeletePost = () => {
    history.push(groupForumsConstants.router.getCategoryRoute(categoryId));
  };

  const fetchReplies = useCallback(
    async (cursor: string | null) => {
      if (threadId === null) {
        return { data: [], nextPageCursor: null, previousPageCursor: null };
      }
      const response = await forumsService.getGroupForumComments(
        groupId,
        categoryId,
        threadId,
        groupForumsConstants.pageCounts.commentsPerPage,
        cursor
      );
      if (response.data.length) {
        addReplies(id, response.data);
      }
      return response;
    },
    [addReplies, categoryId, groupId, id, threadId]
  );

  const {
    isLoadingInitialItems: isLoadingReplies,
    isFetchingNextPage: isFetchingNextRepliesPage,
    error: errorLoadingReplies,
    refetch: refetchReplies,
    fetchMore: fetchNextRepliesPage,
    hasMore: hasMoreReplies
  } = useCursoredData<ForumComment>({
    fetchItems: fetchReplies,
    initialCursor: null,
    compareFn: CompareComments
  });

  const showRepliesSection = useMemo(() => {
    return variant === CommentVariants.Comment && threadId !== null;
  }, [threadId, variant]);

  const onToggleCommentReaction = async (emoteId: string, togglingOn: boolean) => {
    try {
      await forumsService.toggleGroupForumReaction(
        groupId,
        variant === CommentVariants.Reply ? channelId : postId,
        id,
        emoteId,
        togglingOn
      );
      logGroupForumsClickEvent({
        groupId,
        clickTargetType: `toggleCommentReaction${togglingOn ? 'On' : 'Off'}`,
        clickTargetId: emoteId
      });
      return true;
    } catch {
      systemFeedbackService.warning(translate('NetworkError'));
    }
    return false;
  };

  const handleShowReplies = useCallback(() => {
    refetchReplies();
    setShowReplies(true);
  }, [refetchReplies]);

  const handleReply = useCallback(() => {
    if (variant === CommentVariants.Reply && parentCommentId) {
      setReplyToCommentReply(parentCommentId, id);
      logGroupForumsClickEvent({
        groupId,
        clickTargetType: 'replyToReply',
        clickTargetId: id
      });
    } else if (variant !== CommentVariants.Reply) {
      setReplyToCommentOrPost(id);
      if (showRepliesSection && !showReplies && variant === CommentVariants.Comment) {
        handleShowReplies();
      }
      logGroupForumsClickEvent({
        groupId,
        clickTargetType: 'replyToComment',
        clickTargetId: id
      });
    }
  }, [
    handleShowReplies,
    groupId,
    id,
    parentCommentId,
    setReplyToCommentOrPost,
    setReplyToCommentReply,
    showReplies,
    showRepliesSection,
    variant
  ]);

  const handleShowRepliesClicked = useCallback(() => {
    handleShowReplies();
    logGroupForumsClickEvent({
      groupId,
      clickTargetType: 'showCommentReplies',
      clickTargetId: id
    });
  }, [groupId, id, handleShowReplies]);

  const handleMenuOpened = useCallback(() => {
    const isPost = variant === CommentVariants.Post;

    // TODO: uncomment when notifications backend is complete
    /* if (isPost) {
      fetchPostNotificationPreference();
    } else {
      fetchCommentNotificationPreference(id);
    } */

    logGroupForumsClickEvent({
      groupId,
      clickTargetType: isPost ? 'openPostMenuFromComment' : 'openCommentMenu',
      clickTargetId: id
    });
  }, [id, variant, groupId]);

  useEffect(() => {
    // If we add a reply to a comment and we still haven't fetched the replies
    // we should fetch them and open the replies section
    if (replies?.length && showReplies === false) {
      handleShowReplies();
    }
  }, [handleShowReplies, replies, showReplies]);

  useEffect(() => {
    if (errorLoadingReplies) {
      systemFeedbackService.warning(translate('NetworkError'));
    }
  }, [systemFeedbackService, translate, errorLoadingReplies]);

  const overflowButton = () => {
    return (
      <button
        type='button'
        className='group-forums-comment-dropdown-menu-button btn-generic-more-sm'
        title='more'
        onClick={handleMenuOpened}>
        <span className='group-forums-comment-overflow-icon' />
      </button>
    );
  };

  const togglePostNotificationsCallback = useCallback(() => {
    try {
      togglePostNotifications();
      systemFeedbackService.success(translate('Message.NotificationPreferenceUpdated'));
    } catch {
      systemFeedbackService.warning(translate('NetworkError'));
    }
  }, [systemFeedbackService, togglePostNotifications, translate]);

  const toggleCommentNotificationsCallback = useCallback(() => {
    if (variant !== CommentVariants.Comment) {
      return;
    }
    try {
      toggleCommentNotifications(id);
      systemFeedbackService.success(translate('Message.NotificationPreferenceUpdated'));
    } catch {
      systemFeedbackService.warning(translate('NetworkError'));
    }
  }, [id, variant, systemFeedbackService, toggleCommentNotifications, translate]);

  const renderMenu = () => {
    if (variant === CommentVariants.Post) {
      if (!post) return null;
      return (
        <PostMenu
          post={post}
          button={overflowButton()}
          onDelete={onDeletePost}
          onSubscribe={togglePostNotificationsCallback}
        />
      );
    }

    return (
      <CommentMenu
        button={overflowButton()}
        isReply={variant === CommentVariants.Reply}
        createdBy={createdBy}
        commentId={id}
        parentCommentId={parentCommentId}
        threadId={threadId}
        channelId={channelId}
        onSubscribe={toggleCommentNotificationsCallback}
      />
    );
  };

  const onScrollAnimationComplete = useCallback(() => {
    if (scrollToCommentId === id) {
      clearScrollToCommentId();
    }
  }, [id, scrollToCommentId, clearScrollToCommentId]);

  const {
    layout: { useInlineReply }
  } = useForumsUIContext();

  const { disabled: replyDisabled, disabledTooltip: replyDisabledTooltip } = useReplyDisabledState({
    translate
  });

  const showInlineCommentComposer = useInlineReply && isActive;

  const { displayName, hasVerifiedBadge, groupRoleName } = creatorInfo;

  const editedDate = createdAt !== updatedAt ? new Date(updatedAt) : undefined;

  return (
    <React.Fragment>
      <div
        className={classNames(
          'group-forums-comment',
          variant && `group-forums-comment-variant-${variant}`,
          isActive && 'group-forums-comment-active'
        )}>
        <div className='group-forums-comment-header'>
          <UserDisplay
            userId={createdBy}
            createdTime={createdAt}
            userDisplayName={displayName}
            hasVerifiedBadge={hasVerifiedBadge}
            groupRoleName={groupRoleName ?? translate('Label.FormerMember')}
          />
          <div className='group-forums-comment-menu'>{renderMenu()}</div>
        </div>
        {title && <h2 className='group-forums-comment-title'>{title.trim()}</h2>}
        <div className='group-forums-comment-content'>
          {content.trim()}
          {editedDate && (
            <span
              className='group-forums-comment-content-edited-marker'
              title={editedDate.toLocaleString()}>
              &ensp;{translate('Label.EditedMarker')}
            </span>
          )}
        </div>
        <div className='groups-forums-comment-metadata-section'>
          <div className='groups-forums-comment-metadata-reaction-section'>
            <CommentReactions
              initialReactions={reactions}
              onToggleReaction={onToggleCommentReaction}
              viewOnly={!canReact}
            />
          </div>
          {variant !== CommentVariants.Post && (
            <ConditionalTooltip
              id={`reply-tooltip-${id}`}
              placement='left'
              content={replyDisabledTooltip}
              enabled={replyDisabled}>
              <AccessibleDivButton
                onClick={!replyDisabled ? handleReply : undefined}
                className={classNames({
                  'groups-forums-comment-metadata-reply-section': true,
                  disabled: replyDisabled
                })}>
                <span className='group-forums-comment-reply-icon' />
                {translate('Action.Reply')}
              </AccessibleDivButton>
            </ConditionalTooltip>
          )}
        </div>
        {scrollToCommentId === id && <ScrollFlashOverlay onComplete={onScrollAnimationComplete} />}
      </div>
      {showInlineCommentComposer && (
        <div
          className={classNames(
            'group-forums-inline-comment-composer-container',
            variant === CommentVariants.Reply &&
              'group-forums-inline-comment-composer-reply-container',
            variant === CommentVariants.Comment &&
              'group-forums-inline-comment-composer-comment-container'
          )}>
          <div className='group-forums-inline-comment-composer'>
            <CommentComposer autoFocus showCancelButton />
          </div>
        </div>
      )}
      {showRepliesSection && (
        <CommentReplies
          replies={replies}
          isOpen={showReplies}
          onShowReplies={handleShowRepliesClicked}
          onLoadMore={fetchNextRepliesPage}
          isLoading={isLoadingReplies}
          isFetchingMore={isFetchingNextRepliesPage}
          hasMore={hasMoreReplies}
          loadingError={errorLoadingReplies}
          parentId={id}
        />
      )}
    </React.Fragment>
  );
};

export default withTranslations(Comment, groupsConfig);
