import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { groupsConfig } from '../translation.config';
import { usePost } from '../contexts/PostContext';
import { useComposer } from '../contexts/ComposerContext';
import ReplyingToDisplay from './ReplyingToDisplay';
import ContentComposer from './content/ContentComposer';
import useCommentSubmission from '../hooks/useCommentSubmission';
import { logGroupForumsClickEvent } from '../utils/logging';

export type CommentComposerProps = {
  showCancelButton?: boolean;
  autoFocus?: boolean;
} & WithTranslationsProps;

const CommentComposer = ({
  autoFocus,
  showCancelButton = true,
  translate
}: CommentComposerProps): JSX.Element => {
  const { groupId, postId, post, comments, getComment } = usePost();

  const {
    replyingToCommentId,
    parentCommentId,
    mentioningReplyId,
    editingCommentId,
    resetComposerState,
    commentComposerRef
  } = useComposer();

  const handleOnClose = useCallback(() => {
    commentComposerRef?.current?.clearText();
    resetComposerState();
  }, [commentComposerRef, resetComposerState]);

  const {
    submitComment,
    commentSubmissionError,
    clearCommentSubmissionError
  } = useCommentSubmission({
    editingCommentId,
    parentCommentId,
    mentioningReplyId,
    translate
  });

  const handleOnSubmit = useCallback(
    async (content: string) => {
      const logEventData = editingCommentId
        ? { clickTargetType: 'editComment', clickTargetId: editingCommentId }
        : { clickTargetType: 'createComment', clickTargetId: postId };

      logGroupForumsClickEvent({
        groupId,
        ...logEventData
      });

      const success = await submitComment(content);
      if (success) {
        handleOnClose();
      }
    },
    [groupId, postId, editingCommentId, submitComment, handleOnClose]
  );

  const handleOnChange = useCallback(
    (value: string) => {
      if (commentSubmissionError) {
        clearCommentSubmissionError();
      }
    },
    [commentSubmissionError, clearCommentSubmissionError]
  );

  const replyMentionName = useMemo(() => {
    if (mentioningReplyId) {
      const reply = getComment(mentioningReplyId, parentCommentId);
      if (reply) {
        return reply.creatorInfo.displayName;
      }
    }
    return '';
  }, [parentCommentId, mentioningReplyId, getComment]);

  const defaultContent = useMemo(() => {
    if (editingCommentId) {
      const comment = getComment(editingCommentId, parentCommentId);
      return comment?.content.plainText ?? '';
    }
    if (replyMentionName) {
      return `@${replyMentionName} `;
    }
    return '';
  }, [editingCommentId, parentCommentId, replyMentionName, getComment]);

  const [previousDefaultContent, setPreviousDefaultContent] = useState<string>(defaultContent);

  const replyingToUserId = useMemo(() => {
    if (replyingToCommentId && replyingToCommentId !== post?.firstComment.id) {
      const comment = getComment(replyingToCommentId);
      if (comment) {
        return comment.createdBy;
      }
    }
    return undefined;
  }, [post, replyingToCommentId, getComment]);

  const renderLabel = useCallback(() => {
    if (editingCommentId) {
      return translate(parentCommentId ? 'Label.EditReply' : 'Label.EditComment');
    }
    if (replyingToUserId) {
      return <ReplyingToDisplay userId={replyingToUserId} />;
    }
    return null;
  }, [parentCommentId, editingCommentId, replyingToUserId, translate]);

  useEffect(() => {
    if (previousDefaultContent !== defaultContent) {
      setPreviousDefaultContent(defaultContent);
      commentComposerRef?.current?.setText(defaultContent);
    }
  }, [
    editingCommentId,
    commentComposerRef,
    defaultContent,
    previousDefaultContent,
    comments,
    parentCommentId
  ]);

  return (
    <ContentComposer
      autoFocus={autoFocus}
      defaultContent={defaultContent}
      errorMessage={commentSubmissionError}
      label={renderLabel()}
      submitDisabled={!!commentSubmissionError}
      onChange={handleOnChange}
      onSubmit={handleOnSubmit}
      onCancel={showCancelButton ? handleOnClose : undefined}
      onClose={handleOnClose}
      inputRef={commentComposerRef}
    />
  );
};

export default withTranslations(CommentComposer, groupsConfig);
