import React from 'react';
import { Link } from 'react-style-guide';
import { Endpoints } from 'Roblox';
import { Thumbnail2d, ThumbnailAvatarHeadshotSize, ThumbnailTypes } from 'roblox-thumbnails';
import { VerifiedBadgeIconContainer, BadgeSizes } from 'roblox-badges';
import abbreviateTimeUtil from '../../shared/utils/abbreviateTime';

export type UserDisplayProps = {
  userId: number;
  userDisplayName: string;
  hasVerifiedBadge: boolean;
  groupRoleName: string;
  createdTime: string;
};

const UserDisplay = ({
  userId,
  createdTime,
  userDisplayName,
  hasVerifiedBadge,
  groupRoleName
}: UserDisplayProps): JSX.Element | null => {
  const separator = '•';
  const createdDate = new Date(createdTime);
  return (
    <div className='group-forums-user-display'>
      <Link
        className='group-forums-user-display-avatar-username-link text-default'
        url={Endpoints.getAbsoluteUrl(`/users/${userId}/profile`)}>
        <Thumbnail2d
          type={ThumbnailTypes.avatarHeadshot}
          size={ThumbnailAvatarHeadshotSize.size48}
          targetId={userId}
          containerClass='group-forums-user-display-avatar-thumbnail'
          altName={userDisplayName}
        />
        {userDisplayName}
        {hasVerifiedBadge && (
          <VerifiedBadgeIconContainer
            overrideImgClass='verified-badge-icon-user-display'
            size={BadgeSizes.FOOTER}
            titleText={userDisplayName}
          />
        )}
      </Link>
      <div className='user-display-time text-default'>
        &nbsp;
        {separator}
        &nbsp;
        <span className='user-display-time-full-time' title={createdDate.toLocaleString()}>
          {abbreviateTimeUtil.abbreviateTime(createdDate)}
        </span>
      </div>
      <div className='user-display-role text-default'>
        &nbsp;
        {separator}
        &nbsp;
        <span className='user-display-role-name'>{groupRoleName}</span>
      </div>
    </div>
  );
};

export default UserDisplay;
