import React, { useMemo, useCallback } from 'react';
import classNames from 'classnames';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { abbreviateNumber } from 'core-utilities';
import { useHistory } from 'react-router-dom';
import { ForumPost } from '../types';
import { groupsConfig } from '../translation.config';
import UserDisplay from './UserDisplay';
import PostPreviewReactions from './PostPreviewReactions';
import groupForumsConstants from '../constants/groupForumsConstants';
import PostMenu from './PostMenu';
import usePostContext from '../hooks/usePostContext';
import { logGroupForumsClickEvent } from '../utils/logging';

const META_DATA_SEPARATOR = ' • ';

export type PostPreviewProps = {
  showPinned: boolean;
  post: ForumPost;
  refetchPosts: () => void;
  onMenuOpened?: () => void;
  togglePostNotifications: (postId: string) => void;
} & WithTranslationsProps;

const PostPreview = ({
  post,
  showPinned,
  refetchPosts,
  onMenuOpened,
  togglePostNotifications,
  translate
}: PostPreviewProps): JSX.Element | null => {
  const history = useHistory();

  const { state } = usePostContext();

  const onPressPost = (event?: React.MouseEvent) => {
    // suppress href native click
    if (event) {
      event.preventDefault();
    }

    history.push(groupForumsConstants.router.getPostRoute(post.categoryId, post.id));
    document.documentElement.scrollTop = 0;
    logGroupForumsClickEvent({
      groupId: post.groupId,
      clickTargetType: 'openPost',
      clickTargetId: post.id
    });
  };

  // Handle keyboard interactions
  const handleKeyDown = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      onPressPost();
    }
  };

  const handleMenuOpened = useCallback(() => {
    onMenuOpened?.();
    logGroupForumsClickEvent({
      groupId: post.groupId,
      clickTargetType: 'openPostMenu',
      clickTargetId: post.id
    });
  }, [post.groupId, post.id, onMenuOpened]);

  const togglePostNotificationsCallback = useCallback(() => {
    togglePostNotifications(post.id);
  }, [post.id, togglePostNotifications]);

  const replyCount = useMemo(() => {
    if (post.commentCount <= 0) return 0;
    return post.commentCount - 1; // We don't count the first comment as a reply
  }, [post.commentCount]);

  if (state.blockedUserList.length > 0 && state.blockedUserList.includes(post.createdBy)) {
    return null;
  }

  const {
    name: postTitle,
    createdBy,
    createdAt,
    firstComment,
    isUnread,
    isPinned,
    isLocked
  } = post;

  const {
    creatorInfo: { displayName, hasVerifiedBadge, groupRoleName },
    content,
    reactions
  } = firstComment;

  const hasReactions = reactions.length > 0;

  return (
    <div className='group-forums-post-preview'>
      <div className='group-forums-post-preview-header'>
        <UserDisplay
          userId={createdBy}
          createdTime={createdAt}
          userDisplayName={displayName}
          hasVerifiedBadge={hasVerifiedBadge}
          groupRoleName={groupRoleName ?? translate('Label.FormerMember')}
        />
        <div className='group-forums-post-preview-menu'>
          <PostMenu
            post={post}
            onRefetchPosts={refetchPosts}
            onDelete={refetchPosts}
            onSubscribe={togglePostNotificationsCallback}
            button={
              <button
                type='button'
                className='group-forums-post-preview-dropdown-menu-button btn-generic-more-sm'
                title='more'
                onClick={handleMenuOpened}>
                <span className='group-forums-post-preview-overflow-icon' />
              </button>
            }
          />
        </div>
      </div>
      <a
        role='button'
        tabIndex={0}
        className='group-forums-post-preview-content'
        onClick={onPressPost}
        onKeyDown={handleKeyDown}
        href={groupForumsConstants.deepLinks.groupForumPostUrl(
          post.groupId,
          post.categoryId,
          post.id
        )}>
        <div className='group-forums-post-preview-title-container'>
          {isUnread && <div className='group-forums-post-preview-unread-status' />}
          {showPinned && isPinned && (
            <span className='group-forums-post-preview-pinned-status-icon' />
          )}
          {isLocked && <span className='group-forums-post-preview-locked-status-icon' />}
          <h2 className='group-forums-post-preview-title text-emphasis text-overflow'>
            {postTitle}
          </h2>
        </div>
        <div
          className={classNames(
            'group-forums-post-preview-content-comment',
            isUnread ? 'font-bold text-emphasis' : 'text-default'
          )}>
          {content.plainText}
        </div>
        <div className='group-forums-post-preview-meta-data'>
          {hasReactions && (
            <React.Fragment>
              <div className='group-forums-post-preview-meta-data-reactions text-default'>
                <PostPreviewReactions reactions={reactions} />
              </div>
              <div className='group-forums-post-preview-meta-data-separator'>
                {META_DATA_SEPARATOR}
              </div>
            </React.Fragment>
          )}
          <div className='group-forums-post-preview-meta-data-replies text-default'>
            <span className='group-forums-post-preview-replies-icon' />
            {abbreviateNumber.getAbbreviatedValue(replyCount)}{' '}
            {replyCount === 1 ? translate('Label.Reply') : translate('Label.Replies')}
          </div>
        </div>
      </a>
    </div>
  );
};

export default withTranslations(PostPreview, groupsConfig);
